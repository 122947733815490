@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");
body {
  margin: 0;
  font-family: 'Zilla Slab', serif;
}

a {
  color: maroon;
}

a:hover {
  color: maroon;
}

nav {
  box-shadow: 0px 0.1px 10px #463f3f;
}

#about {
  background: #CAC531;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #d4b500, #ffc525);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d4b500, #ffc525);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
  min-height: 100vh;
}

#about h1 {
  font-weight: bold;
  color: white;
  text-shadow: 3px 3px 2px darkred;
}

#about p {
  font-size: 1.25rem;
}


#bio-pic {
  height: 275px;
  width: 275px;
  border-radius: 50%;
}

.card-text {
  font-family: "Helvetica Neue";
  font-size: 15px;
}

.card-title {
  font-size: 30px;
}

#landing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  /* font-size: 6em; */
  
  background: #CAC531;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #d4b500, #ffc525);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #d4b500, #ffc525);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#hover:hover {
text-decoration: underline;
}
#landing > h1 {
  font-weight: bold;
  text-shadow: 3px 3px 1px darkred; 
  pointer-events: fill;
}

#github-logo {
  font-size: 40px;
}

#landing > h2 {
  text-shadow: 3px 3px 1px darkred;
  pointer-events: fill;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}